"use client";
import Image from "next/image";
import Link from "next/link";
import rehypeRaw from "rehype-raw";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
// import { usePathname } from "next/navigation";

//icons
import { FaEnvelope, FaTiktok } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { Button } from "../Elements/Button/Button";
import { FaXTwitter } from "react-icons/fa6";
import { usePathname } from "next/navigation";

export const Footer = ({ options, bg: passed_bg }) => {
  //console.log("*************")
  //console.log(options)
  //console.log("*************")

  const path = usePathname();
  const bg = path.includes("/news") ? "#ffffff" : passed_bg;
  console.log("bg", bg, path);

  return (
    <>
      <footer className="flex items-center flex-col  mx-auto relative h-auto overflow-hidden pb-[25px]">
        {/* SEMI CIRCLE*/}
        <div
          style={{ backgroundColor: `${bg}` }}
          className="z-[-2] w-[100vw] h-[100px] absolute top-0"
        />
        <div
          style={{ backgroundColor: `${bg}` }}
          className=" z-[-1] w-[500vw] sm:w-[300vw] lg:w-[250vw] h-[200%] rounded-[50%] absolute top-0"
        />

        <div className="container flex flex-col justify-center items-center">
          <h2 className="text-6xl font-semibold mb-14 mt-[100px] text-center">
            Contact
          </h2>
          <Link
            target="_blank"
            aria-label="Google maps (Opens in new tab)"
            href={options.acf.google_maps_share_link}
            className="tabindexlink text-center"
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {options.acf.address.replace(/\n/gi, "<br/> \n")}
            </ReactMarkdown>
          </Link>
          <Link
            href={`tel:${options.acf.phone_number}`}
            className="tabindexlink ext-center my-4"
          >
            {options.acf.phone_number}
          </Link>

          {/* <Link href={"mailto:" + options.acf.email_address} className="tabindexlink text-center">{options.acf.email_address}</Link> */}

          {/* Social media buttons */}

          <ul className="flex flex-wrap gap-7 space-y mt-[50px]">
            <li className="list-none">
              <Link
                className="tabindexlink"
                aria-label="Email"
                href={`mailto:${options?.acf?.email_address}`}
              >
                <FaEnvelope size={20} color="black" />
              </Link>
            </li>

            <li className="list-none">
              <Link
                className="tabindexlink"
                aria-label="Instagram (Opens in new tab)"
                target="_blank"
                href={options?.acf?.instagram_url}
              >
                <FaInstagram size={20} color="black" />
              </Link>
            </li>
            {options?.acf?.tiktok_url && (
              <li className="list-none">
                <Link
                  className="tabindexlink"
                  aria-label="Instagram (Opens in new tab)"
                  target="_blank"
                  href={options?.acf?.tiktok_url}
                >
                  <FaTiktok size={20} color="black" />
                </Link>
              </li>
            )}
            <li className="list-none">
              <Link
                className="tabindexlink"
                aria-label="Twitter (Opens in new tab)"
                target="_blank"
                href={options?.acf?.twitter_url}
              >
                <FaXTwitter size={20} color="black" />
              </Link>
            </li>

            <li className="list-none">
              <Link
                className="tabindexlink"
                aria-label="LinkedIn (Opens in new tab)"
                target="_blank"
                href={options?.acf?.linkedin_url}
              >
                <FaLinkedinIn size={20} color="black" />
              </Link>
            </li>

            <li className="list-none">
              <Link
                className="tabindexlink"
                aria-label="Facebook (Opens in new tab)"
                target="_blank"
                href={options?.acf?.facebook_url}
              >
                <FaFacebook size={20} color="black" />
              </Link>
            </li>
          </ul>

          {/* Get in touch button */}
          <Button url="/get-in-touch">Get in Touch</Button>

          {/*  other images  */}

          <ul className="mt-14 flex flex-wrap mb-14 gap-10 justify-center">
            {options.acf.icons.map((icon) => {
              return (
                <li className="list-none" key={icon.url}>
                  <Link
                    className={`tabindexlink h-[100px] ${icon.unrestricted ? "" : "w-[100px]"} flex justify-center items-center`}
                    aria-label={`${icon?.icon?.alt} (opens in new tab)`}
                    href={icon.url}
                    target={icon.unrestricted ? undefined : "_blank"}
                  >
                    <img
                      alt={icon.icon.alt ? icon.icon.alt : ""}
                      className="relative h-[90px] object-contain w-[auto]"
                      height={120}
                      width={120}
                      src={icon.icon.url}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>

          {/* small print */}

          <div className="flex flex-col lg:flex-row w-full ">
            <ul className="flex flex-wrap justify-center w-full lg:w-auto lg:justify-start mr-auto text-xs text-neutral-700 ">
              <li className="noIndent list-none">Copyright 2023 ·</li>
              <li className="noIndent mx-1 list-none">
                Liverpool City Region Combined Authority
              </li>
              <li className="noIndent list-none">
                · Website by <a href="https://agentmarketing.co.uk">Agent</a>
              </li>
            </ul>
            <ul className="flex flex-wrap justify-center w-full mt-2 lg:my-0 mb-4 lg:mb-0 lg:w-auto lg:justify-start ml-auto text-xs text-neutral-700 ">
              <li className="noIndent list-none pr-[5px]">
                <Link
                  className="tabindexlink"
                  aria-label="Modern Slavery Statement"
                  href={"/modern-slavery-statement/"}
                >
                  Modern Slavery Statement |{" "}
                </Link>{" "}
              </li>
              <li className="noIndent list-none pr-[5px]">
                <Link
                  className="tabindexlink"
                  aria-label="Fair Processing Notice"
                  href={"/fair-processing-notice/"}
                >
                  Fair Processing Notice |{" "}
                </Link>{" "}
              </li>
              <li className="noIndent list-none">
                <Link
                  className="tabindexlink"
                  aria-label="Accessibility Statement"
                  href={"/accessibility-statement/"}
                >
                  {" "}
                  Accessibility Statement |
                </Link>{" "}
              </li>
              <li className="noIndent list-none mx-1">
                <Link
                  className="tabindexlink"
                  aria-label="Privacy Policy"
                  href={"/privacy-policy"}
                >
                  Privacy Policy
                </Link>
              </li>
              <li className=" noIndent list-none">
                {" "}
                <Link
                  className="tabindexlink"
                  target="_blank"
                  aria-label="Sitemap"
                  href={"/sitemap.xml"}
                >
                  {" "}
                  | Sitemap
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};
